/**
 * React Modal
 *
 * A modal component for React.
 */

import React from 'react';
import ReactDOM from 'react-dom';

import './styles.scss';


export default function Modal({ children, className, closeButtonText = 'X', onClose = undefined, root = null }) {
    if (onClose === undefined) {
        throw new Error("onClose must not be undefined; it should be a function or `null`");
    }

    return ReactDOM.createPortal(
        <div className={`ReactModal ${className} ${onClose === null ? 'ReactModal--not-closable' : ''}`}>
            <div className="ReactModal__gutter" onClick={e => onClose && onClose(e)} />
            <div className="ReactModal__window">
                {(onClose && closeButtonText) && <button className="ReactModal__close" onClick={e => onClose(e)}>{closeButtonText}</button>}
                <div className="ReactModal__content">
                    {children}
                </div>
            </div>
        </div>,
        root || document.body
    );
};
